
h5 {
    color: #8196A9;
    font-weight: 500 !important;
}

h1 {
    color: #404296 !important;
    font-weight: 900 !important;
    width: auto;
}

p {
    color: #313558 !important;
    font-size: 20px !important;
    width: 550px;
}

ul {
    padding-left: 0;
}

li {
    color: #313558;
    list-style: none;
}

.subtitle {
    color: #404296 !important;
    font-weight: 500;
    text-transform: uppercase;
}

.pmoc {
    height: auto;
    padding: 40px 250px 0 250px;
    display: flex;
    justify-content: start;
}

.pmocHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.pmoc i {
    margin-right: 5px;
    color: #F58635;
}

.whatsapp i {
    color: #fff !important;
}

.multiSplitImg {
    margin-right: 10px;
}

.listTitle {
    font-size: 24px;
    font-weight: 900;
    color: #404296;
}

/* For extremely small screen devices (595px and below) */
/* @media only screen and (max-width: 595px) {...} */

/* Medium screen devices (768px and above) */
@media screen and (max-width: 768px) {
    .whatsapp {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0;
    }
    .pmocImg {
        margin-top: 5px;
        height: auto;
        width: 100%;
    }
    h5 {
        color: blue;
        font-weight: 500 !important;
        font-size: 18px !important;
    }
    
    h1 {
        color: #000000;
        font-weight: 700 !important;
        width: 100px;
        font-size: 20px !important;
    }
    
    p {
        color: #313558 !important;
        font-size: 16px !important;
    }
    .listTitle {
        font-size: 18px;
        font-weight: 600;
        color: #404296;
    }
    .pmoc {
        height: auto !important;
        padding: 30px 50px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .pmoc {
        flex-direction: column-reverse;
    }

    .pmoc h1,
    .pmoc p {
        width: 100%;
    }
    .pmoc img {
        height: 200px !important;
        width: 260px;
        margin-top: 10px;
        margin-bottom: 20px;
    }
}

/* Big screen devices (1024px and above) */
/* @media only screen and (min-width: 1024px) {...} */