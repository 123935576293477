
.homepage h5 {
    color: #8196A9;
    font-weight: 500 !important;
}

.homepage h1 {
    color: #000000 !important;
    font-weight: 700 !important;
    width: 300px;
}

.homepage p {
    color: #313558 !important;
    font-size: 34px !important;
    width: 550px;
}

.confortSession {
    height: 500px;
    padding: 100px 250px 0 250px;
    display: flex;
    justify-content: center;
}

.confortSession img{
    height: 380px;
    float: right;
    /* padding-bottom: 10px; */
}

.servicesSession {
    height: 600px;
    padding: 200px 250px 200px 250px;
    background-color: #BDDCF9;
    display: flex;
    justify-content: center;
    align-items: center;
}

.servicesSession h1 {
    color: #000000;
    font-weight: 700 !important;
    width: 300px;
}

.servicesSession p {
    color: #313558 !important;
    font-size: 16px !important;
    width: 550px;
}

.pmocSession {
    /* background-image: url('../assets/servicos2.png'); */
    height: 600px;
    padding: 200px 250px 200px 250px;
    color: #000000;
    font-weight: 500;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-items: initial;
    text-align: start;
}

.pmocSession h1{
    color:#313558;
    width: 200px;
    font-size: 42px;
    font-weight: 600 !important;
}

.card {
    background-color: #fff;
    width: 278px;
    height: auto;
    min-height: 450px;
    border-radius: 70px !important;
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    margin-left: 3px;
    margin-right: 3px;
}

.card h1{
    color:#313558;
    width: 200px;
    font-size: 24px;
    font-weight: 600 !important;
    margin-top: 20px;
    padding: 0 30px 0 30px;
}

.card p {
    font-size: 14px;
    font-weight: 600;
    width: auto;
}

.typeOfService {
    height: 200px;
    width: 200px;
}

/* For extremely small screen devices (595px and below) */
/* @media only screen and (max-width: 595px) {...} */

/* Medium screen devices (768px and above) */
@media screen and (max-width: 768px) {
    .homepage h5 {
        font-weight: 500 !important;
        font-size: 18px !important;
    }
    
    .homepage h1 {
        color: #000000;
        font-weight: 700 !important;
        width: 100px;
        font-size: 20px !important;
    }
    
    .homepage p {
        color: #313558 !important;
        font-size: 16px !important;
    }
    .confortSession {
        height: auto !important;
        padding: 30px 50px !important;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }
    .confortSession h1, .confortSession p {
        width: 100%;
    }
    .confortSession img{
        height: 200px !important;
        width: 260px;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .servicesSession {
        height: auto;
        width: 100%;
        padding: 20px 25px 20px 25px;
        display: flex;
        flex-direction: column;
    }
    .typeOfService {
        height: 100px;
        width: 100px;
    }
    .card {
        min-height: 330px;
        margin-bottom: 15px;
    }
    .pmocSession {
        height: auto;
        width: 100%;
        padding: 20px 25px 20px 25px;
        display: flex;
        flex-direction: column;
    }
}

/* Big screen devices (1024px and above) */
/* @media only screen and (min-width: 1024px) {...} */