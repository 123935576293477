.whatsappButton {
    border-radius: 25.5px !important;
    color: #fff !important;
    background-color: #00C04B !important;
    border: none !important;
    width: 254px;
    height: 43px;
    font-weight: 700 !important;
}

i {
    font-weight: 700;
    font-size: 24px !important;
    margin-left: 10px;
}


/* Medium screen devices (768px and above) */
@media screen and (max-width: 768px) {
    .whatsappButton {
        width: auto !important;
        height: auto;
        padding: 15px !important;
    }
}