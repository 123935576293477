
.contactPage h5 {
    color: #8196A9;
    font-weight: 500 !important;
}

.contactPage h1 {
    color: #000000;
    font-weight: 700 !important;
    width: 600px;
}

.contactPage p {
    color: #313558 !important;
    font-size: 34px;
    width: 550px;
}

.title {
    align-items: initial;
    text-align: start;
}

.contactForm {
    height: 100vh;
    padding: 100px 250px 0 250px;
    display: flex;
    justify-content: start;
    flex-direction: column;
}

.contactForm label {
    width: 340px;
    display: flex;
    flex-direction: column;
    color: #4f6c8a;
    font-weight: bold;
    margin-top: 10px;
}

.contactForm input {
    background-color: #e8f4ff;
    border: 2px solid #bddcf9;
    border-radius: 5px;
}

.nameAndEmail {
    display: flex;
    justify-content: space-between;
    width: 700px;
}

.message {
    width: 700px;
    height: 150px;
    background-color: #e8f4ff;
    border: 2px solid #bddcf9;
    border-radius: 5px;
}

.submitButton {
    width: 300px;
    margin-top: 15px;
    background-color: #BDDCF9 !important;
    border: 2px solid #000 !important;
    color: #4F6C8A;
    font-weight: bold;
}



/* For extremely small screen devices (595px and below) */
/* @media only screen and (max-width: 595px) {...} */

/* Medium screen devices (768px and above) */
@media screen and (max-width: 768px) {
    .contactPage h1 {
        width: auto;
        margin-top: 30px !important;
    }
    .contactForm {
        padding: 20px !important;
        height: auto;
    }
    .nameAndEmail {
        width: auto;
        flex-direction: column;
    }
    .contactForm label {
        width: auto;
    }
    .message {
        width: auto;
        height: 150px;
    }
    .submitButton {
        width: auto;
    }
}

/* Big screen devices (1024px and above) */
/* @media only screen and (min-width: 1024px) {...} */