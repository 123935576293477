/* .header {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    margin-left: 150px !important;
} */

.itemNav {
    padding-top: 30px;
}

a {    
    color: #313558 !important;
    text-decoration: none !important;
}

.whatsapp {
    padding-top: 20px;
    /* padding-right: 150px; */
}

.logo {
    height: 70px;
    padding-top: 25px;
    /* padding-left: 5px; */
}


/* Medium screen devices (768px and above) */
@media screen and (max-width: 768px) {
    .whatsapp {
        display: none;
    }
}

/* MENU MOBILE */
  a {
    text-decoration: none;
    font-family: sans-serif;
  }
  
  a:hover {
    /* background: rgba(0, 0, 0, 0.05); */
    color: #313558 !important;
    text-decoration: none !important;
  }
  
  #logo {
    height: 100px;
    /* box-shadow: #313558; */
    /* box-shadow: 10px 5px 5px black; */
  }

  .whatsapp {
    margin-left: 50px;
    padding-bottom: 20px;
  }
  
  #header {
    box-sizing: border-box;
    height: 70px;
    padding: 4rem 4rem 2rem 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  #menu {
    display: flex;
    list-style: none;
    /* gap: 0.5rem; */
    /* align-items: center;
    justify-content: center; */
    font-weight: 700;
  }
  
  #menu a {
    display: block;
    padding: 0.5rem;
  }
  
  #btn-mobile {
    display: none;
  }
  
  @media (max-width: 768px) {  
    a {
        color: #404296 !important;
    }
    #header {
        padding: 4rem 2rem 2rem 2rem;
      }
    #logo {
        padding: 0 !important;
    }
    #menu {
      display: block;
      position: absolute;
      width: 100%;
      top: 100px;
      right: 0px;
      background: #EEEFF0;
      transition: 0.6s;
      z-index: 1000;
      height: 0px;
      visibility: hidden;
      overflow-y: hidden;
    }
    #nav.active #menu {
      height: calc(100vh - 70px);
      visibility: visible;
      overflow-y: auto;
    }
    #menu li {
      width: 80% !important;
      padding: 1rem 0;
      margin: 0 1rem;
      border-bottom: 2px solid rgba(0, 0, 0, 0.05);
    }
    #btn-mobile {
      display: flex;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      border: none;
      background: none;
      cursor: pointer;
      gap: 0.5rem;
    }
    #hamburger {
      border-top: 2px solid;
      width: 20px;
    }
    #hamburger::after,
    #hamburger::before {
      content: '';
      display: block;
      width: 20px;
      height: 2px;
      background: currentColor;
      margin-top: 5px;
      transition: 0.3s;
      position: relative;
    }
    #nav.active #hamburger {
      border-top-color: transparent;
    }
    #nav.active #hamburger::before {
      transform: rotate(135deg);
    }
    #nav.active #hamburger::after {
      transform: rotate(-135deg);
      top: -7px;
    }
  }