
.servicesPage h5 {
    color: #8196A9;
    font-weight: 500 !important;
}

.servicesPage h1 {
    color: #000000 !important;
    font-weight: 700 !important;
    width: 300px;
}

.servicesPage .servicesSubtitle {
    color: #313558 !important;
    font-size: 34px !important;
    width: 550px;
}

.mainSession {
    height: 500px;
    padding: 100px 250px 0 250px;
    display: flex;
    justify-content: start;
}

.mainSession img{
    height: 300px;
    float: right;
}

.maintenanceSession {
    height: 700px;
    padding: 100px 150px 0 150px;
    display: flex;
    justify-content: space-between;
    background-color: #BDDCF9;
}

.maintenanceSessionText {
    width: 300px;
    display: flex;
    flex-direction: column;
}
.maintenanceSessionText h1 {
    width: 200px;
}

.maintenanceSessionText p {
    font-size: 20px;
    width: 400px;
    padding-top: 30px;
}

.maintenanceSession img{
    height: 500px;
    float: left;
    margin-right: 30px;
}

.teamSession {
    background-image: url('../assets/servicos2.png');
    height: 700px;
    padding: 100px 250px 0 250px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.teamSessionText h1 {
    color: #000000;
    font-weight: 700 !important;
    width: 600px;
}

.teamSessionText p {
    color: #313558 !important;
    font-size: 24px;
    width: 350px;
}


/* For extremely small screen devices (595px and below) */
/* @media only screen and (max-width: 595px) {...} */

/* Medium screen devices (768px and above) */
@media screen and (max-width: 768px) {
    .mainSession {
        height: auto !important;
        padding: 30px 50px !important;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }
    .mainSession h1 {
        width: auto;
    }
    .servicesPage .servicesSubtitle {
        font-size: 20px !important;
        width: auto !important;
    }
    .maintenanceSession {
        height: auto !important;
        padding: 30px 50px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .maintenanceSession img{
        height: 150px;
    }
    .maintenanceSession p{
        width: 100%;
    }
    .teamSession {
        height: auto !important;
        padding: 30px 50px !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .teamSessionText {
        display: flex;
        width: 100%;
    }
}

/* Big screen devices (1024px and above) */
/* @media only screen and (min-width: 1024px) {...} */