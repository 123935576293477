
.teamSession {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: auto;
}
.photo {
    min-width: 310px;
    height: auto;
    min-height: 310px;
    border-radius: 0px !important;
    padding: 20px;
    align-items: center;
    justify-content: center;
    margin-left: 3px;
    margin-right: 3px;
}

.photoImage {
    height: 300px;
    width: 300px;
}

/* For extremely small screen devices (595px and below) */
/* @media only screen and (max-width: 595px) {...} */

/* Medium screen devices (768px and above) */
@media screen and (max-width: 768px) {
    .teamSession {
        display: flex;
        flex-direction: column;
    }
    .photoImage {
        max-height: 200px;
        max-width: 200px;
    }
    .photo {
        padding: 50px;
        min-height: 330px;
        margin-bottom: 15px;
    }
}

/* Big screen devices (1024px and above) */
/* @media only screen and (min-width: 1024px) {...} */