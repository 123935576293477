.footer {
    /* padding-top: 50px; */
    /* width: 100%;
    padding: 0 250px 20px 250px; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4F6C8A;
    font-weight: 700;
}

.buttons {
    display: flex;
    /* margin-left: 50px; */
    /* width: 150px; */
}

.copyright {
    font-weight: 300;
    width: auto;
    text-align: right;
    margin-left: auto;
}

.firstLine{
    text-align: right !important;
    color: #fff !important;
    font-size: 12px !important;
}

.itemNav {
    padding-top: 30px;
}

.footer a {    
    color: #fff !important;
    font-size: 12px !important;
}

.footer i {
    background-color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 3px;
}

.footer .fa-whatsapp {
    color: #00C04B !important;
    padding: 2px 5px;
}

.footer .fa-instagram {
    color: #E90C20!important;
    padding: 2px 5px;
}

.footer .fa-facebook {
    color: #008FF1 !important;
    padding: 2px 7px;
}


/* Medium screen devices (768px and above) */
@media screen and (max-width: 768px) {    
    .footer {
        display: flex;
        flex-direction: column;
        justify-content: left;
    }
    
    .buttons {
        display: flex;
        /* margin-left: 50px; */
        /* width: 150px; */
    }
    
    .copyright {
        font-weight: 300;
        width: auto;
        text-align: right;
        margin-left: auto;
    }
    
    .firstLine{
        text-align: right !important;
        color: #fff !important;
        font-size: 12px !important;
    }
    
    .itemNav {
        padding-top: 30px;
    }
    
    .footer a {    
        color: #fff !important;
        font-size: 12px !important;
    }
    
    .footer i {
        background-color: #fff;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: 3px;
    }
}