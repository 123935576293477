.container {
    height: 100vh;
    /* padding: 100px 0px 0px 0px; */
    margin: 100px 0 250px 0;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.accreditedsLogos {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 350px 350px 350px;
    grid-auto-rows: 200px;
    align-items: center;
    justify-items: center;
}


/* For extremely small screen devices (595px and below) */
/* @media only screen and (max-width: 595px) {...} */

/* Medium screen devices (768px and above) */
@media screen and (max-width: 768px) {
    .container {
        height: auto;
        margin-top: 50px;
        margin-bottom: 0;
    }
    .accreditedsPage h5, h1 {
        color: #8196A9;
        display: flex;
        width: 100%;
    }
    .accreditedsLogos {
        margin-top: 20px;
        margin-bottom: 20px;
        grid-template-columns: 35px;
        grid-auto-rows: 180px;
    }
}

/* Big screen devices (1024px and above) */
/* @media only screen and (min-width: 1024px) {...} */