
.specialtiesPage h5 {
    color: #8196A9;
    font-weight: 500 !important;
}

.specialtiesPage h1 {
    color: #404296 !important;
    font-weight: 900 !important;
    width: 300px;
}

.specialtiesPage p {
    color: #313558 !important;
    font-size: 20px !important;
    width: 550px;
}

.specialtiesPage ul {
    padding-left: 0;
}

.specialtiesPage li {
    color: #313558;
    list-style: none;
}

.subtitle {
    color: #404296 !important;
    font-weight: 500;
    text-transform: uppercase;
}

.vrfSession, .fanCoilSession, .multiSplitSession {
    height: auto;
    padding: 100px 250px 0 250px;
    display: flex;
    justify-content: center;
}

.vrfSession img,
.fanCoilSession img, 
.multiSplitSession img{
    height: 250px;
    float: right;
}

.vrfSession i,
.fanCoilSession i, 
.multiSplitSession i{
    margin-right: 5px;
    color: #F58635;
}

.multiSplitImg {
    margin-right: 10px;
}

.listTitle {
    font-size: 30px;
    font-weight: 900;
    color: #404296;
}

/* For extremely small screen devices (595px and below) */
/* @media only screen and (max-width: 595px) {...} */

/* Medium screen devices (768px and above) */
@media screen and (max-width: 768px) {
    .specialtiesPage h5 {
        font-weight: 500 !important;
        font-size: 18px !important;
    }
    
    .specialtiesPage h1 {
        /* color: #000000;
        font-weight: 700 !important; */
        width: 100px;
        font-size: 30px !important;
    }
    
    .specialtiesPage p {
        color: #313558 !important;
        font-size: 16px !important;
    }
    .vrfSession,
    .fanCoilSession, 
    .multiSplitSession {
        height: auto !important;
        padding: 30px 50px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .vrfSession,
    .fanCoilSession {
        flex-direction: column-reverse;
    }

    .vrfSession h1,
    .vrfSession p,
    .fanCoilSession h1, 
    .fanCoilSession p, 
    .multiSplitSession h1,
    .multiSplitSession p {
        width: 100%;
    }
    .vrfSession img,
    .fanCoilSession img, 
    .multiSplitSession img {
        height: 200px !important;
        width: 260px;
        margin-top: 10px;
        margin-bottom: 20px;
    }
}

/* Big screen devices (1024px and above) */
/* @media only screen and (min-width: 1024px) {...} */